import liveConfig from '../assets/config/environment.json';
import localConfig from '../assets/config/environment-local.json';
import { environment } from '../environments/environment';

const config =
  environment.currentEnvironment === 'local' ? localConfig : liveConfig;

const defaultRegionalCode = 'fr';
const allowedRegionalCodes = ['fr', 'de'];
const defaultAllowedCountriesPhoneList = ['fr', 'be', 'lu', 'de', 'es', 'it'];
const defaultPreferredCountriesPhoneList = ['be', 'fr', 'de', 'lu'];

const FRENCH_COOKIE_NAME = 'kst-29115d37d62f60f89faceee39e26d662';
const BELGIAN_COOKIE_NAME = 'KST-1AA4579CD3CDF8FEFC6651BD8548900C';
const LUXEMBOURGIAN_COOKIE_NAME = 'kst-nxs9o41agjkwt5czj47b6t4b8wje3cte';
const DEFAULT_COOKIE_NAME = 'kst-4bac055f65a71bba4ba383ed733e3d03';
const GERMAN_COOKIE_NAME = 'KST-B0A45616600B2A10FE2CE62259D2AC20';
const TEST_COOKIE_NAME = '';

export type ENVIRONMENT = 'local' | 'dev' | 'test' | 'preprod' | 'prod';

export class ConfigService {
  static gtmId(): string {
    const gtmId = config.gtmId;
    if (gtmId == null || gtmId == '') {
      return 'no-action-key';
    }
    return gtmId;
  }

  static isProd(): boolean {
    return this.currentEnvironment().toLowerCase().includes('prod');
  }

  static isLocal(): boolean {
    return this.currentEnvironment() === 'local';
  }

  static notLocal(): boolean {
    return !this.isLocal();
  }

  static currentEnvironment(): ENVIRONMENT {
    return config.currentEnvironment as ENVIRONMENT;
  }

  static sentryDsn() {
    return this.notLocal() ? config.sentryDsn : undefined;
  }

  static isFormTemplatesDisabled(): boolean {
    return config.formTemplatesDisabled === 'true';
  }

  static isReferralsDisabled(): boolean {
    return config.referralsDisabled === 'true';
  }

  static isMaintenanceModeActive(): boolean {
    return config.maintenanceModeActive === 'true';
  }

  static getMaintenanceModeActivePath(): string {
    return '/maintenance_mode-' + config.maintenanceAvailableAppPath;
  }

  static isCampaignTrackingDisabled(): boolean {
    return config.campaignTrackingDisabled === 'true';
  }

  static apiUrl() {
    return environment.apiUrl;
  }

  static apiPortalUrl() {
    return environment.apiPortalUrl;
  }

  static lazeoWebsiteLink() {
    if (this.currentDomain() === 'de') {
      return 'https://www.cleanskin.de';
    }
    return `https://www.lazeo.${this.currentDomain()}`;
  }

  static lazeoWebsiteTitle() {
    if (this.currentDomain() === 'de') {
      return 'cleanskin.de';
    }
    return `lazeo.${this.currentDomain()}`;
  }

  static regionalResourceCode() {
    return config.regionalResourceCode &&
      allowedRegionalCodes.includes(config.regionalResourceCode)
      ? config.regionalResourceCode
      : defaultRegionalCode;
  }

  static allowedCountriesPhoneList() {
    const allowedCountriesPhoneList =
      config.allowedCountriesPhoneList.split(' ');
    const hasCountryCodeTwoDigitsIsoStandard = allowedCountriesPhoneList.every(
      (code) => code.length === 2,
    );
    return allowedCountriesPhoneList.length &&
      hasCountryCodeTwoDigitsIsoStandard
      ? allowedCountriesPhoneList
      : defaultAllowedCountriesPhoneList;
  }

  static preferredCountriesPhoneList() {
    const preferredCountriesPhoneList =
      config.preferredCountriesPhoneList.split(' ');
    const hasCountryCodeTwoDigitsIsoStandard =
      preferredCountriesPhoneList.every((code) => code.length === 2);
    return preferredCountriesPhoneList.length &&
      hasCountryCodeTwoDigitsIsoStandard
      ? preferredCountriesPhoneList
      : defaultPreferredCountriesPhoneList;
  }

  static getDomain() {
    const currentHostname = this.currentHostname();
    return currentHostname.replace('my', '');
  }

  static getCookieName(): string {
    const currentHostname = this.currentHostname();

    if (currentHostname === 'my.lazeo.fr') {
      return FRENCH_COOKIE_NAME;
    } else if (currentHostname === 'my.lazeo.be') {
      return BELGIAN_COOKIE_NAME;
    } else if (currentHostname === 'my.lazeo.lu') {
      return LUXEMBOURGIAN_COOKIE_NAME;
    } else if (currentHostname === 'my.lazeo.com') {
      return DEFAULT_COOKIE_NAME;
    } else if (currentHostname === 'my.cleanskin.de') {
      return GERMAN_COOKIE_NAME;
    } else {
      return TEST_COOKIE_NAME;
    }
  }

  private static currentHostname(): string {
    return window.location.hostname;
  }

  private static currentDomain(): string {
    const currentHostname = this.currentHostname();
    if (currentHostname.endsWith('.be')) {
      return 'be';
    }
    if (currentHostname.endsWith('.fr')) {
      return 'fr';
    }
    if (currentHostname.endsWith('.lu')) {
      return 'lu';
    }
    if (currentHostname.endsWith('.de') || currentHostname === 't.csk.li') {
      return 'de';
    }
    if (
      currentHostname.endsWith('.com') ||
      currentHostname === 't.lzo.li' ||
      currentHostname.endsWith('.eu') ||
      currentHostname === 'localhost'
    ) {
      return 'com';
    }
    throw new Error(`Unknown hostname: ${currentHostname}`);
  }
}
