import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { Sha256Service } from '../../analytics/sha-256.service';
import { ConfigService } from '../../config.service';

const EXPIRY_MONTHS = 3;

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(private readonly sha256Service: Sha256Service) {}

  createOrUpdateCookie(): Observable<string> {
    const cookieUuid = uuidv4();
    const cookieName = ConfigService.getCookieName();
    const existingCookie = this.getCookie(cookieName);
    const domain = ConfigService.getDomain();
    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + EXPIRY_MONTHS);
    const cookieAttributes = [
      `domain=${domain}`,
      'path=/',
      'HttpOnly',
      'Secure',
      'SameSite=Lax',
      `expires=${expiryDate.toUTCString()}`,
    ];

    return this.sha256Service.calculateSHA256Hash(cookieUuid).pipe(
      tap((hashedCookieValue) => {
        const cookieValue = existingCookie
          ? existingCookie.split(';')[0]
          : `kstcssuid=${hashedCookieValue}`;

        const cookieString = `${cookieName}=${cookieValue};${cookieAttributes.join(
          ';',
        )}`;
        document.cookie = cookieString;
      }),
    );
  }

  private getCookie(name: string): string | null {
    const cookie = document.cookie
      .split(';')
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith(name + '='));

    if (cookie) {
      return cookie.substring(cookie.indexOf('=') + 1);
    }

    return null;
  }
}
